import configurationApi from '@/api/configuration.api';

export const configurationService = (function () {
  'use strict';

  let filterValuesConfig = null;
  let defaultFilterValues = null;

  /**
   * Guarda/actualiza una configuración del usuario
   * @param {*} id id de la configuración
   * @param {*} data configuración
   */
  async function save(id, data) {
    const userId = localStorage.getItem('userId');
    const configuration = {
      id,
      data
    };
    const response = await configurationApi.save(userId, configuration)
    localStorage.setItem('userConfiguration', JSON.stringify(response.configurations))
  }

  /**
   * Obtiene una configuración en particular del usuario
   * @param {*} id identificador de la configuración
   */
  async function get(id) {
    let config;
    try {
      /*
      Primero, se busca en el localstorage, luego en la collection
      */
      const userConfigurationLocal = JSON.parse(localStorage.getItem('userConfiguration'))
        ? JSON.parse(localStorage.getItem('userConfiguration'))
        : [];
      const userId = localStorage.getItem('userId');
      config = userConfigurationLocal.length > 0 ? userConfigurationLocal.find(c => c.id === id) : undefined;
      if (!config) {
        const response = await configurationApi.get(userId, id);
        if (response) {
          userConfigurationLocal.push(response);
          localStorage.setItem('userConfiguration', JSON.stringify(userConfigurationLocal));
          return response;
        } else {
          return undefined;
        }
      } else {
        return config;
      }
    } catch {
      return undefined;
    }
  }

  /**
   * Para obtener las configuraciones de un usuario.
   * Se utiliza en el autologin de cybermapa.
   * @param {*} userId 
   */
  async function getConfigurationByUserAndEnterprise(userId, enterpriseId) {
    try {
      if (userId) {
        const result = await configurationApi.getConfigurationByUserAndEnterprise(userId, enterpriseId)
        return result.data
      } else {
        return null
      }

    } catch (e) {
      console.error(e)
      return null
    }
  }

  async function getFilterValuesConfig() {
    if (!filterValuesConfig) {
      filterValuesConfig = await configurationApi.getFilterValuesConfig();
    }
    return filterValuesConfig;
  }

  async function getDefaultFilterValues() {
    if (!defaultFilterValues) {
      defaultFilterValues = await configurationApi.getDefaultFilterValues();
    }
    return defaultFilterValues;
  }

  return {
    save,
    get,
    getConfigurationByUserAndEnterprise,
    getFilterValuesConfig,
    getDefaultFilterValues
  }
})();
