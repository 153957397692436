const state = {
    entitySelectorItems: [],
    selectedItem: null,
    entitySelectorConfiguration: { value: '', text: '', name: '' },
    details: null
}

const getters = {
    getEntitySelectorItems(state) {
        return state.entitySelectorItems
    },
    getSelectedItem(state) {
        return state.selectedItem
    },
    getEntitySelectorConfiguration(state) {
        return state.entitySelectorConfiguration
    },
    getDetails(state) {
        return state.details
    }
}

const actions = {
    setEntitySelectorItems ({ commit }, payload) {
        commit('commitEntitySelectorItems', payload)
    },
    setSelectedItem ({ commit }, payload) {
        commit('commitSelectedItem', payload)
    },
    setEntitySelectorConfiguration ({ commit }, payload) {
        commit('commitEntitySelectorConfiguration', payload)
    },
    setDetails ({ commit }, payload) {
        commit('commitDetails', payload)
    }
}

const mutations = {
    commitEntitySelectorItems (state, entitySelectorItems) {
        state.entitySelectorItems = entitySelectorItems
    },
    commitSelectedItem (state, selectedItem) {
        state.selectedItem = selectedItem
    },
    commitEntitySelectorConfiguration (state, entitySelectorConfiguration) {
        state.entitySelectorConfiguration = entitySelectorConfiguration
    },
    commitDetails (state, details) {
        state.details = details
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}