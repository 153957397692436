import http from "@/middleware";

const authApi = {
  async validateUser(authDto) {
    const data = await http.post("/api/login/validate", authDto);
    return data;
  },
  async getResetPasswordToken(authDto) {
    const result = await http.post("/api/auth/reset-password-token", authDto);
    return result;
  },
  async resetPassword(body) {
    const result = await http.put("/api/auth/reset-password", body);
    return result;
  },
  async sendResetPasswordEmail(email, config) {
    const result = await http.post(
      "/api/email/send-reset-password",
      { email },
      config
    );
    return result;
  },
  async validateResetPasswordToken() {
    const result = await http.get("/api/auth/validate-password-reset-token");
    return result;
  },
  async refreshToken(data) {
    const user = await http.post("/api/login/refresh-token", data);
    return user;
  },
  async refreshTokenSecondFactor(data) {
    const user = await http.post("/api/login/refresh-token-second-factor", data);
    return user;
  },
  async logout() {
    const user = await http.put("/api/login/logout");
    return user;
  },
};

export default authApi;
