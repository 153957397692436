import authApi from "@/api/auth.api";
import { messagingService } from '@/business/messagingService';
//import Vue from 'vue'
import { navBarService } from "@/business/navBarService";
import { NOTIFICATIONS_PAGE_SIZE } from '@/constants/constants';
import store from "../store/store";

export const loginService = (function () {
  "use strict";

  async function login(userName, password) {
    const authDto = {
      username: userName,
      password: password
    };
    const result = await authApi.validateUser(authDto);
    return result.data;
  }

  async function refreshToken(enterprise) {
    const result = await authApi.refreshToken({ enterprise });
    return result.data;
  }
  async function refreshTokenSecondFactor(enterprise, secondFactorToken) {
    const result = await authApi.refreshTokenSecondFactor({ enterprise, secondFactorToken });
    return result.data;
  }
  

  async function refreshAndUpdateUserData(enterprise, i18nInstance) {
    const userSession = await this.refreshToken(enterprise);
    await this.updateUserData(userSession, i18nInstance);
  }

  async function updateUserData(userSession) {
    store.dispatch("user/setToken", userSession.sessionToken);
    
    if (userSession.user && userSession.user.userId) {
      store.dispatch("user/setUserId", userSession.user.userId);
    } else {
      store.dispatch("user/setUserId", undefined);
    }


    if (userSession.enterprise && userSession.enterprise._id) {
      localStorage.setItem("enterpriseId", userSession.enterprise._id);
    } else {
      localStorage.removeItem("enterpriseId");
    }

    if (userSession.enterprise && userSession.enterprise.themes) {
      store.dispatch("user/setThemes", userSession.enterprise.themes);
    } else {
      localStorage.removeItem("themes");
      store.dispatch("user/setThemes", undefined);
    }

    if (userSession.enterprise && userSession.enterprise.timezone !== undefined) {
      store.dispatch("user/setEnterpriseTimezone", userSession.enterprise.timezone);
    } else {
      localStorage.removeItem("enterpriseTimezone");
      store.dispatch("user/setEnterpriseTimezone", undefined);
    }

    if (userSession.user && userSession.user.internationalization) {
      store.dispatch("user/setInternationalization", userSession.user.internationalization);
    } else {
      localStorage.removeItem("internationalization");
      store.dispatch("user/setInternationalization", undefined);
    }

    if (userSession.enterprise && userSession.enterprise.logo) {
      store.dispatch("user/setLogo", userSession.enterprise.logo);
    } else {
      localStorage.removeItem("logo");
      store.dispatch("user/setLogo", undefined);
    }

    if (userSession.enterprise && userSession.enterprise.loginLogo) {
      store.dispatch("user/setLoginLogo", userSession.enterprise.loginLogo);
    } else {
      localStorage.removeItem("loginLogo");
      store.dispatch("user/setLoginLogo", undefined);
    }

    if (userSession.enterprise && userSession.enterprise.loginBackgroundImage) {
      store.dispatch(
        "user/setLoginBackgroundImage",
        userSession.enterprise.loginBackgroundImage
      );
    } else {
      localStorage.removeItem("loginBackgroundImage");
      store.dispatch("user/setLoginBackgroundImage", undefined);
    }

    if (userSession.enterprise && userSession.enterprise.customTimeSpan) {
      localStorage.setItem(
        "enterpriseTimeSpan",
        JSON.stringify(userSession.enterprise.customTimeSpan)
      );
    } else {
      localStorage.removeItem("enterpriseTimeSpan");
    }
    store.dispatch("user/setPrivileges", userSession.user.privileges);
    store.dispatch("app/setNavBarButtons", navBarService.getMenuByUser());

    localStorage.setItem(
      "userConfiguration",
      JSON.stringify(userSession.configurations)
    );
    store.dispatch("user/setAdmin", userSession.user.superAdmin || false);
    let userId = userSession.user.userId;
    messagingService.getNotReadCount(userId).then((badgeCount) => {
      store.commit("app/setBadgeCount", { badgeCount });
    })
    // total
    messagingService.getNotificationsTotalCount(userId).then((totalCount) => {
      store.commit("app/setNotificationsTotalCount", totalCount);
    })
    // notificaciones
    messagingService.findNotifications(NOTIFICATIONS_PAGE_SIZE, 1, userId).then(response => {
      store.commit("app/setNotifications", response);
    })
  }

  async function logout() {
    await authApi.logout();
    store.dispatch("user/clearSessionData", {});
    store.dispatch("app/resetAppData", {});
  }
  return {
    login,
    refreshToken,
    refreshAndUpdateUserData,
    updateUserData,
    logout,
    refreshTokenSecondFactor
  };
})();
