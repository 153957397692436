import authApi from "@/api/auth.api";
import { configurationService } from "@/business/configurationService";
import { enterpriseService } from "@/business/enterprise.service";
import { messagingService } from "@/business/messagingService";
import { navBarService } from "@/business/navBarService";
import store from "@/store/store";
import jwt from "jsonwebtoken";

export const authService = (function() {
  "use strict";

  async function login(authDto) {
    let result = await authApi.validateUser(authDto);
    return result.data;
  }

  /**
   * Realiza el proceso de login que es efectuado por el componente Login
   * para el autologin de cybermapa
   * @param {*} sessionToken
   */
  async function cypermapaAutoLogin(sessionToken) {
    store.dispatch("user/clearSessionData");
    const userData = jwt.decode(sessionToken);

    store.dispatch("user/setUsername", userData.user);
    store.dispatch("user/setUserId", userData.userId);
    store.dispatch("user/setEmail", userData.email);
    store.dispatch("user/setPrivileges", userData.privileges);
    store.dispatch("user/setToken", sessionToken);
    store.dispatch("user/setAdmin", false);
    store.dispatch("user/setLocale", "es");
    store.dispatch("app/setNavBarButtons", navBarService.getMenuByUser());

    if (userData.userId) {
      await messagingService.configureNotifications(userData);
    }
  }
  /**
    * Configura la empresa seleccionada
    * cuando se realiza el autologin de cybermapa
    * @param {*} enterpriseId
  */
  async function setEnterprise(enterpriseId) {
    store.dispatch("user/setEnterpriseId", enterpriseId)
    const userId = store.getters['user/getUserId'];

    if (enterpriseId) {
      const enterpriseData = await enterpriseService.getEnterpiseLoginData(enterpriseId)
      if (enterpriseData) {
        // themes
        store.dispatch("user/setThemes", enterpriseData.themes)
        // logo
        store.dispatch("user/setLogo", enterpriseData.logo)
        // login logo
        store.dispatch("user/setLoginLogo", enterpriseData.loginLogo)
        // background image
        store.dispatch("user/setLoginBackgroundImage", enterpriseData.loginBackgroundImage)
      }
    }
    if (userId && enterpriseId) {
      let configData = await configurationService.getConfigurationByUserAndEnterprise(userId, enterpriseId)
      if (configData) {
        configData.push({id: 'enterpriseId', data:enterpriseId})
        localStorage.setItem('userConfiguration', JSON.stringify(configData))
      } else {
        localStorage.removeItem('userConfiguration')
      }
    }
  }

  return {
    login,
    cypermapaAutoLogin,
    setEnterprise
  };
})();
