import http from '@/middleware'

const generalConfigurationApi = {
  async getConfigSystemVersion (id) {
    let result = await http.post("/api/general-configuration/get-config-system-version", { id })
    return result
  }
}

export default generalConfigurationApi
