export default function setup () {
  // eslint-disable-next-line no-extend-native
  Array.prototype.clean = function () {
    this.splice(0, this.length)
  }

  // eslint-disable-next-line no-extend-native
  Array.prototype.cleanAndUpdate = function (newArray) {
    this.clean()
    this.pushArray(newArray)
  }

  // eslint-disable-next-line no-extend-native
  Array.prototype.pushArray = function (newArray) {
    if (newArray) {
      newArray.forEach(element => {
        this.push(element)
      })
    }
  }
}
