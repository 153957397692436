import { ResetPasswordType } from "@/constants/constants";
import i18n from "@/i18n";
import store from "@/store/store";
import Vue from "vue";
import Router from "vue-router";
import { authService } from "@/business/auth.service";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/LoginView.vue")
    },
    {
      path: "/reset-password",
      name: "emailResetPassword",
      component: () =>
        import(
          /* webpackChunkName: "emailResetPassword" */ "./views/EmailResetPasswordView.vue"
        )
    },
    {
      path: "/reset-password/user/:token",
      name: "resetPasswordUser",
      meta: { type: ResetPasswordType.USER_RESET },
      component: () =>
        import(
          /* webpackChunkName: "resetPassword" */ "./views/ResetPasswordView.vue"
        )
    },
    {
      path: "/reset-password/next-login/:token",
      name: "resetPasswordNextLogin",
      meta: { type: ResetPasswordType.NEXT_LOGIN },
      component: () =>
        import(
          /* webpackChunkName: "resetPassword" */ "./views/ResetPasswordView.vue"
        )
    },
    {
      path: "/reset-password/expiration/:token",
      name: "resetPasswordExpiration",
      meta: { type: ResetPasswordType.EXPIRATION },
      component: () =>
        import(
          /* webpackChunkName: "resetPassword" */ "./views/ResetPasswordView.vue"
        )
    },
    {
      path: "/home",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "DashboardComponent" */ "./components/dashboard/DashboardComponent.vue")
    },
    {
      path: "/alerts",
      name: "alerts",
      component: () =>
        import(/* webpackChunkName: "AlertsComponent" */ "./components/alerts/AlertsComponent.vue")
    },
    {
      path: "/inventory",
      name: "inventory",
      component: () =>
        import(/* webpackChunkName: "InventoryComponent" */ "./components/inventory/InventoryComponent.vue")
    },
    {
      path: "/dischargeSummary",
      name: "dischargeSummary",
      component: () =>
        import(/* webpackChunkName: "DischargeSummaryComponent" */ "./components/dischargeSummary/DischargeSummaryComponent.vue")
    },
    {
      path: "/idling-discharge-summary",
      name: "idlingDischargeSummary",
      component: () =>
        import(/* webpackChunkName: "IdlingDischargeSummaryComponent" */ "./components/idling-discharge-summary/IdlingDischargeSummaryComponent.vue")
    },
    {
      path: "/charge-summary",
      name: "chargeSummary",
      component: () =>
        import(/* webpackChunkName: "ChargeSummaryComponent" */ "./components/chargeSummary/ChargeSummaryComponent.vue")
    },
    {
      path: "/performance-summary",
      name: "performanceSummary",
      component: () =>
        import(/* webpackChunkName: "PerformanceSummaryComponent" */ "./components/performance-summary/PerformanceSummaryComponent.vue")
    },   
    {
      path: "/select-enterprise",
      name: "EnterpriseSelection",
      component: () => import("./components/enterprise-selection/EnterpriseSelectorComponent.vue"),
      meta: { keys: { and: [""] } }
    },
    { path: "*", redirect: "/home" }
  ]
});

router.beforeEach((to, from, next) => {
  // Ocultar header y footer en el login
  if (to && to.name === "login" || to.name === "EnterpriseSelection") {
    store.commit("app/hideHeader");
    store.commit("app/hideFooter");
  } else if (
    to &&
    to.path === "/home" &&
    (to.query.token ||
      !(store.state.user.token || localStorage.getItem("token")))
  ) {
    if (to.query.token) {
      authService.cypermapaAutoLogin(to.query.token).then(() => {
        next({
          path: "/select-enterprise"
        });
      });
    } else {
      next({
        path: "/login"
      });
    }
  } else if (to && to.path === "/home" && (!store.state.user.enterpriseId || store.state.user.haveGoogleAutenticatorSecret) ) { // Si no tiene una enterprise seleccionada
    next({
      path: "/select-enterprise"
    });
  } else {
    store.commit("app/showHeader");
    store.commit("app/showFooter");
  }

  // Configurar lenguaje
  if (localStorage.getItem("locale")) {
    i18n.locale = localStorage.getItem("locale");
  } else {
    i18n.locale = navigator.language;
  }
  next();
});

export default router;
