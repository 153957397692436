import enterpriseApi from "@/api/enterprise.api"
import { ColvenTheme } from '@/constants/constants'
import { authService } from "@/business/auth.service";
import { messagingService } from "@/business/messagingService";
import { loginService } from "@/business/loginService";
import i18n from '@/i18n'
export const enterpriseService = (function() {
    "use strict"

    async function changeEnterprise(enterpriseId){
        
            await authService.setEnterprise(enterpriseId);
            let userSession = await loginService.refreshToken(
                enterpriseId
            );
            if (userSession.enterprise) {
                await loginService.updateUserData(userSession, i18n, true);
                await messagingService.configureNotifications(userSession);                
            }
          
    }
    async function setEnterpriseAndSecondFactor(enterpriseId,secondFactorToken){
        await authService.setEnterprise(enterpriseId);
        let userSession = await loginService.refreshTokenSecondFactor(enterpriseId,secondFactorToken);
        if (userSession.enterprise) {
            await loginService.updateUserData(userSession, i18n, true);
            await messagingService.configureNotifications(userSession);                
        }
      
}

    /**
     * Para obtener los datos de la empresa del usuario
     * Se utiliza en el autologin de cybermapa
     * @param {*} enterpriseId
     */
    async function getEnterpiseLoginData(enterpriseId) {
        try {
            if (enterpriseId) {
                let result = await enterpriseApi.getEnterpiseLoginData(enterpriseId)
                return result.data
            } else {
                return {
                    themes: ColvenTheme,
                    loginLogo: undefined,
                    logo: undefined,
                    loginBackgroundImage: undefined
                }
            }
            
        } catch (e) {
            console.error(e)
            return {
                themes: ColvenTheme,
                loginLogo: undefined,
                logo: undefined,
                loginBackgroundImage: undefined
            }
        }
    }

    return {
        getEnterpiseLoginData,
        changeEnterprise,
        setEnterpriseAndSecondFactor
    }
})()
